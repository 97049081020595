import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-headermenu',
  templateUrl: './headermenu.page.html',
  styleUrls: ['./headermenu.page.scss'],
})
export class HeadermenuPage implements OnInit {

  constructor(
    private router: Router,
    private pop: PopoverController
  ) { }

  ngOnInit() {
  }

  // goto home page
  goHome() {
    this.router.navigate(['/cric/home']);
    this.pop.dismiss();
  }
  goNews() {
    this.router.navigate(['/cric/news']);
    this.pop.dismiss();
  }
  // goBlog() {
  //   this.router.navigate(['/cric/articles']);
  //   this.pop.dismiss();
  // }
  goFixtures() {
    this.router.navigate(['/cric/fixtures']);
    this.pop.dismiss();
  }
  goMore() {
    this.router.navigate(['/cric/more']);
    this.pop.dismiss();
  }
  goLive(){
    this.router.navigate(['/cric/live']);
    this.pop.dismiss();
  }
}
