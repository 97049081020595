import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { ApikeygetService } from './apikeyget.service';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {
  // local api
  // baseUrl: string = 'http://localhost:3000/api/';
  // blogimg: string = 'http://localhost:3000/uploads/blogimg/';
  // advimg: string = 'http://localhost:3000/uploads/advimg/';
  // live api
  baseUrl: string = 'https://sky11live.com/api/';
  blogimg: string = 'https://sky11live.com/uploads/blogimg/';
  advimg: string = 'https://sky11live.com/uploads/advimg/';
  simg: string = 'https://sky11live.com/uploads/shorts/';

  liveUrl: string = 'http://apicricketchampion.in/apiv4/';
  livepiKey: string = '68352c40e73f0d00dc1bcab407633cdc';
  // livepiKey: any;


  constructor(
    private http: HttpClient,
    private toast: ToastController,
    private loading: LoadingController,
    private apigetkey: ApikeygetService
  ) {
    // this.apigetkey.getliveapikey().subscribe((res: any) => {
    //   if (res.success == 1) {
    //     this.livepiKey = res.data[0].apikey;
    //   } else {
    //     console.log('server errror');
    //     return;
    //   }
    // });
  }
  // live api start

  // Http Options
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  // get card for scroes
  getCardData(): Observable<any> {
    return this.http.get(this.liveUrl + 'homeList/' + this.livepiKey);
  }

  // trending 
  getTreanding(): Observable<any> {
    return this.http.get("https://sky11.news/wp-json/wp/v2/posts" );
  }
  getNews(): Observable<any> {
    return this.http.get(this.liveUrl + 'news/' + this.livepiKey);
  }

  

  // news details by news id
  getNewsDetails(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'newsDetail/' + this.livepiKey, data);
  }
  getLiveMatchList(): Observable<any> {
    return this.http.get(this.liveUrl + 'liveMatchList/' + this.livepiKey);
  }

  // upcoming matches
  getUpcomingMatch(): Observable<any> {
    return this.http.get(this.liveUrl + 'upcomingMatches/' + this.livepiKey);
  }

  // recent matches
  getRecentMatch(): Observable<any> {
    return this.http.get(this.liveUrl + 'recentMatches/' + this.livepiKey);
  }

  // team ranking
  getTeamRank(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'teamRanking/' + this.livepiKey, data);
  }

  // live score by match id
  getLiveScore(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'liveMatch/' + this.livepiKey, data);
  }

  // match information 
  getMatchInfo(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'matchInfo/' + this.livepiKey, data);
  }

  // get player 11
  getPlEleven(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'squadByMatchId/' + this.livepiKey, data);
  }
  // get all possible player
  getAllPl(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'playersByMatchId/' + this.livepiKey, data);
  }
  // fantacy of match
  getFantasy(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'matchFancy/' + this.livepiKey, data);
  }
  // get commentary
  getCommentary(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'commentary/' + this.livepiKey, data);
  }
  //score card of match
  getScores(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'scorecardByMatchId/' + this.livepiKey, data);
  }
  // get match odds
  getMatchOdds(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'matchOddHistory/' + this.livepiKey, data);
  }

  // player ranking 
  getPlayerRank(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'playerRanking/' + this.livepiKey, data);
  }
  // get match stats
  getMatchStats(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'matchStats/' + this.livepiKey, data);
  }
  getPointTable(data: any): Observable<any> {
    // return this.http.post(this.liveUrl + 'pointsTable/' + this.livepiKey, data);
    return this.http.post(this.liveUrl + 'pointsNewTable/' + this.livepiKey, data);
  }
  //get trending series list
  getSeriesList(): Observable<any> {
    return this.http.get(this.liveUrl + 'seriesList/' + this.livepiKey);
  }
  // get serieslist by its id
  getTrendingMatchList(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'matchesBySeriesId/' + this.livepiKey, data);
  }
  //check the userstatus for forgotpass
  checUforForgotPass(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'buserfstat', data);
  }

  // man of the match api
  getMOMofmatch(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'manOfMatch/' + this.livepiKey, data)
  }
  //get recent match by series id
  getRecentMbySid(data: any): Observable<any> {
    return this.http.post(this.liveUrl + 'matchesRecentBySeriesId/' + this.livepiKey, data);
  }

  // end live api

  // privacy policy
  getPolicy(): Observable<any> {
    return this.http.get(this.baseUrl + 'ppolicyget');
  }
  // term and conditions
  getTerms(): Observable<any> {
    return this.http.get(this.baseUrl + 'termsget');
  }
  // about us
  getAbout(): Observable<any> {
    return this.http.get(this.baseUrl + 'aboutget');
  }
  //blog data
  getBlog(): Observable<any> {
    return this.http.get(this.baseUrl + 'getblog');
  }
  //blog details
  blogDetails(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'blogdetail', data);
  }

  // add android users
  addAndUsers(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'adduser', data);
  }
  // add web browser users
  addWebUsers(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'addwebuser', data, this.httpOptions);
  }
  // save token into database 
  saveFcmT(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'addusert', data);
  }

  // get fcm token 
  getFcmT(): Observable<any> {
    return this.http.get(this.baseUrl + 'getusert');
  }

  // user register
  userSignUp(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'busersignup', data)
  }

  // user signin
  userSignIn(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'buserlogin', data);
  }

  // userProfile
  userProfile(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'buserprofile', data);
  }

  // userchpass
  userChPass(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'buserchpass', data);
  }

  // get user satatus
  getbuserStat(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'buserstat', data);
  }

  // get user details by using userid and mobile number
  getBuserSForgot(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'buserfstat', data);
  }

  // set the token and username and get
  token: any;
  cluser: any;

  setToken(token) {
    this.token = localStorage.setItem('token', token);
  }

  setUser(user) {
    this.cluser = localStorage.setItem('cluser', user);
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    return localStorage.getItem('cluser');
  }

  // get app version
  getAppVer(): Observable<any> {
    return this.http.get(this.baseUrl + 'getappver');
  }
  // get advertisement list 
  getAdvt(): Observable<any> {
    return this.http.get(this.baseUrl + 'getbanneractive');
  }
  // get advertisement list 
  getDisplayAdvt(): Observable<any> {
    return this.http.get(this.baseUrl + 'getactviedisplayad');
  }
  // get usert
  getuserTbyT(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'getuserbyt', data);
  }

  // get active shorts
  getActiveShorts(): Observable<any> {
    return this.http.get(this.baseUrl + 'getactiveshorts');
  }
  // update like
  updateShortsLike(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'updatelike', data);
  }
  // update share
  updateShortShare(data: any): Observable<any> {
    return this.http.post(this.baseUrl + 'updateshare', data);
  }

  // err toast message
  async errToast(data: any) {
    const toast = await this.toast.create({
      message: data,
      color: 'danger',
      position: 'bottom',
      duration: 2000
    });
    return await toast.present();
  }

  // success toast
  async successToast(data: any) {
    const toast = await this.toast.create({
      message: data,
      color: 'secondary',
      position: 'bottom',
      duration: 2000
    });
    return await toast.present();
  }

  // show spinner
  async showSpinner() {
    let loading = await this.loading.create({
      spinner: 'circular',
      translucent: true,
      cssClass: 'loaderscss',
      backdropDismiss: true,
      keyboardClose: true,
      duration:1000
    });
    return await loading.present();
  }

  // set vs lang
  public setVslang(vslang: any) {
    localStorage.setItem("vsLang", vslang);
  }

  // get voice lang
  public getVsLangSay() {
    return localStorage.getItem("vsLang");
  }
}