import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: any;
  websit: boolean = false;

  // language translator
  public flive: string;
  public fabout: string;
  public termc: string;
  public ppolicy: string;
  public copyt: string;
  public allrrser: string;
  public language: string;

  constructor(
    private router: Router,
    private platform: Platform,
    private _translate: TranslateService
  ) { }

  ngOnInit() {
    this.year = new Date().getFullYear();

    if (this.platform.is('capacitor')) {
      this.websit = false;
    } else {
      this.websit = true;
    }
    this._initTranslate(localStorage.getItem("setlang"));
  }


  gotoHome() {
    // this.router.navigate(['/tabs/tab3']);
    this.router.navigate(['/livemlist']);
  }

  goAbout() {
    this.router.navigate(['/aboutus']);
  }

  goTerm() {
    this.router.navigate(['/termandc']);
  }

  goPrivacy() {
    this.router.navigate(['/privacypolicy']);
  }

  // language translator
  ionViewDidEnter(): void {
    this._initTranslate('hn');
  }

  _initialiseTranslation(): void {
    this._translate.get('footer.flive').subscribe((res: string) => {
      this.flive = res;
    });
    this._translate.get('footer.fabout').subscribe((res: string) => {
      this.fabout = res;
    });
    this._translate.get('footer.termc').subscribe((res: string) => {
      this.termc = res;
    });
    this._translate.get('footer.ppolicy').subscribe((res: string) => {
      this.ppolicy = res;
    });
    this._translate.get('footer.copyt').subscribe((res: string) => {
      this.copyt = res;
    });
    this._translate.get('footer.allrrser').subscribe((res: string) => {
      this.allrrser = res;
    });
  }

  public changeLanguage(): void {
    this._translateLanguage();
  }

  _translateLanguage(): void {
    this._translate.use(this.language);
    this._initialiseTranslation();
  }

  _initTranslate(ev: any) {
    // Set the default language for translation strings, and the current language.
    this._translate.setDefaultLang('en');
    if (ev) {
      this.language = ev;
    }
    else {
      // Set your language here
      this.language = 'en';
    }
    this._translateLanguage();
  }

}
