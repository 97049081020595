import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number = 150, trail: string = '...'): string {
    if (value.length <= limit) {
      return value;
    }
    
    let truncatedText = value.substring(0, limit);
    const lastWordIndex = truncatedText.lastIndexOf(' ');
    truncatedText = truncatedText.substring(0, lastWordIndex) + trail;
    
    return truncatedText;
  }
}
