import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';


import { TruncatePipe } from '../app/trmaTruncatePipe/TruncatePipe.pipe';

import { IonicModule, IonicRouteStrategy,IonRouterOutlet } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedHeaderModule } from './shared/header/header.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
// import { TextToSpeechAdvanced } from '@awesome-cordova-plugins/text-to-speech-advanced/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { Globalization } from '@ionic-native/globalization/ngx';
import { Globalization } from '@awesome-cordova-plugins/globalization/ngx';
// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
// const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
// import firebase + enviornment
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { environment } from '../environments/environment';

import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { AdMobFree } from '@ionic-native/admob-free/ngx';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}


@NgModule({
  declarations: [AppComponent ,    TruncatePipe],
  entryComponents: [],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedHeaderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    // AdsenseModule.forRoot({
    //   adClient: 'ca-pub-4333057922482054',
    //   adSlot: 1070867188,
    // }),
    // SocketIoModule.forRoot(config)
  ],
  providers: [{
    provide: RouteReuseStrategy,
    useClass: IonicRouteStrategy
  },
    SocialSharing,
    ScreenOrientation,
    // TextToSpeechAdvanced,
    Globalization,
    Insomnia,
    AppVersion,
    StatusBar,
    AdMobFree
    ],
  bootstrap: [AppComponent],
})
export class AppModule { }