import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApikeygetService {
  baseUrl: string = 'http://sky11.com/api/';
  // baseUrl: string = 'http://localhost:3000/api/';

  constructor(
    private http: HttpClient
  ) { }

  // get liveapikey
  getliveapikey(): Observable<any> {
    return this.http.get(this.baseUrl + 'getcrkliveapi');
  }

}
