import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonSegment, IonSlides, ModalController } from '@ionic/angular';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, { SwiperOptions, Autoplay, Navigation, Pagination, Scrollbar, A11y, Virtual, EffectFade } from 'swiper';
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Virtual, Autoplay, EffectFade]);

@Component({
  selector: 'app-exapp',
  templateUrl: './exapp.page.html',
  styleUrls: ['./exapp.page.scss'],
})
export class ExappPage implements OnInit {
  @ViewChild('mysegment', { static: false }) segComponent?: IonSegment;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(
    private router: Router,
    private modalctrl: ModalController
  ) { }

  ngOnInit() {
    this.segment = 0;
  }

  // exit app
  exitApp() {
    navigator['app'].exitApp();
  }

  // close modal
  closeModal() {
    this.modalctrl.dismiss();
  }


  slideOpts: SwiperOptions = {
    // initialSlide: 0,
    direction: 'horizontal',
    slidesPerView: 1,
    keyboard: false,
    mousewheel: false,
    scrollbar: false,
    navigation: false,
    pagination: false,
    speed:100
  }

  segment = 0;
  async segmentChanged(event) {
    await this.swiper.swiperRef.slideTo(this.segment);
    this.swiper.swiperRef.update();
  }

  async slideChanged() {
    this.segComponent.value = await String(this.swiper.swiperRef.activeIndex);
    this.segment = await this.swiper.swiperRef.activeIndex;
    this.focusSegment(this.segment + 1);
  }

  focusSegment(segmentId) {
    document.getElementById('seg-' + segmentId).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center'
    });
  }
}