// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
firebaseConfig : {
  apiKey: "AIzaSyC29wuPbJ-Yh49o_9prgRLWS9u1HragC78",
  authDomain: "sky11-d41bb.firebaseapp.com",
  databaseURL: "https://sky11-d41bb-default-rtdb.firebaseio.com",
  projectId: "sky11-d41bb",
  storageBucket: "sky11-d41bb.appspot.com",
  messagingSenderId: "76112602672",
  appId: "1:76112602672:web:843828c0d44cfae43eaff5",
  measurementId: "G-CFGPKQ1K3Y"
}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
