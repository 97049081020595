import { Component, OnInit } from '@angular/core';
import { ApiserviceService } from 'src/app/services/apiservice.service';

import { Platform } from '@ionic/angular';
@Component({
  selector: 'app-displayad',
  templateUrl: './displayad.component.html',
  styleUrls: ['./displayad.component.scss'],
})
export class DisplayadComponent implements OnInit {
  advData: any;
  adbimg = this.apiservice.advimg;
  advimg: any;
  ads:Array<any> = [];
  //normal slider
  slideOpts = {
    slidesPerView: 1,
    spaceBetween: 20,
    speed: 4000,
    loop: true,
    centeredSlides: true,
    autoplay: {
      delay: 4000
    },
    pagination: {
    
      clickable: false, // Set this to false to disable pagination bullets
    },
  };
 
  constructor(
    private platform: Platform,
    private apiservice: ApiserviceService
  ) { }

  ngOnInit() {
    this.getDisAdbImg();
  }

  // get the image from api
  getDisAdbImg() {
    
    this.apiservice.getDisplayAdvt().subscribe((res: any) => {
      if (res.success == 1) {
        this.advimg = res.data.length;
        this.advData = res.data;
         
          if (this.advimg == 0 && this.platform.is('capacitor')) {
            
          }
           
      } else {
        this.apiservice.errToast("Display image not found");
      }
    });
  }

  // open ad in browser
  openAd(url: any) {
    window.open(url, "_system");
  }
  
}

