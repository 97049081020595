import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HeadermenuPage } from 'src/app/headermenu/headermenu.page';
import { ApiserviceService } from 'src/app/services/apiservice.service';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
declare var window: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  lightmode: boolean = false;
  darkmode: boolean = false;
  webhead: boolean = false;
  mobhead: boolean = false;

  homeh: boolean = false;
  otherh: boolean = false;
  hname: any;

  public hlive: string;
  public hhome: string;
  public hnews: string;
  public hblog: string;
  public hfixt: string;
  public hmore: string;
  public language: string;
  themeSelect: any;
  cuser: any; // current logged in user
  constructor(
    private platform: Platform,
    private router: Router,
    private popover: PopoverController,
    private _translate: TranslateService,
    private apiservice: ApiserviceService,
    private statusBar: StatusBar
  ) {
    window.header = this;
  }

  ngOnInit() {
    this.showHideToolbar();
    // this.getToken();
    this.getUser();
    // this.darkMode();
    this.showHideHeader();
    this._initTranslate(localStorage.getItem("setlang"));
    this.themeSelect = localStorage.getItem('theme');
    if (this.themeSelect) {
      this.lightDark();
    } else {
      // this.darkMode();
      this.lightDark();
    }
  }

  showHideToolbar() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // console.log(event.url);
        if (event.url === "/cric/home") {
          this.homeh = true;
          this.otherh = false;
        } else {
          this.homeh = false;
          this.otherh = true;
        }
        if (event.url === "/cric/fixtures") {
          this.hname = 'Matches';
        } else if (event.url === "/cric/shorts") {
          this.hname = 'Shorts';
        } else if (event.url === "/cric/articles" || event.url === "/trendingseries") {
          this.hname = 'Series';
        } else if (event.url === "/bloglist") {
          this.hname = 'Blogs';
        }
        else {
          this.hname = 'Sky11';
        }
      }
    });
  }

  ngAfterViewInit() {
    this.cuser = this.cid;//localStorage.getItem('cluser');
  }

  lightMode() {
    this.lightmode = false;
    this.darkmode = true;
    localStorage.setItem("theme", 'light');
    document.body.setAttribute('data-theme', 'light');
    this.statusBar.backgroundColorByHexString('#034281');
  }

  darkMode() {
    this.lightmode = true;
    this.darkmode = false;
    localStorage.setItem("theme", 'dark');
    document.body.setAttribute('data-theme', 'dark');
    this.statusBar.backgroundColorByHexString('#0C131A');
  }

  // show hide header
  showHideHeader() {
    if (this.platform.is('capacitor')) {
      this.mobhead = true;
      this.webhead = false;
    } else {
      this.webhead = true;
      this.mobhead = false;
    }
  }

  // goto home page
  goHome() {
    this.router.navigate(['/cric/home']);
  }
  goNews() {
    this.router.navigate(['/cric/news']);
  }
  // goBlog() {
  //   this.router.navigate(['/cric/articles']);
  // }
  goFixtures() {
    this.router.navigate(['/cric/fixtures']);
  }
  goMore() {
    this.router.navigate(['/cric/more']);
  }
  goLive() {
    this.router.navigate(['/livemlist']);
  }

  gotoSearch() {
    this.router.navigate(['/searchpl']);
  }

  goProfile() {
    this.router.navigate(['/profile']);
  }

  // show popover
  async showPop(ev) {
    let pop = await this.popover.create({
      component: HeadermenuPage,
      cssClass: 'headermenu',
      event: ev
    });
    return await pop.present();
  }

  _initialiseTranslation(): void {
    this._translate.get('header.hlive').subscribe((res: string) => {
      this.hlive = res;
    });
    this._translate.get('header.hhome').subscribe((res: string) => {
      this.hhome = res;
    });
    this._translate.get('header.hnews').subscribe((res: string) => {
      this.hnews = res;
    });
    this._translate.get('header.hblog').subscribe((res: string) => {
      this.hblog = res;
    });
    this._translate.get('header.hfixt').subscribe((res: string) => {
      this.hfixt = res;
    });
    this._translate.get('header.hmore').subscribe((res: string) => {
      this.hmore = res;
    });
  }

  public changeLanguage(): void {
    this._translateLanguage();
  }

  _translateLanguage(): void {
    this._translate.use(this.language);
    this._initialiseTranslation();
  }

  _initTranslate(ev: any) {
    // Set the default language for translation strings, and the current language.
    this._translate.setDefaultLang('en');
    if (ev) {
      this.language = ev;
    }
    else {
      // Set your language here
      this.language = 'en';
    }
    this._translateLanguage();
  }


  // select light or dark
  lightDark() {
    if (this.themeSelect == 'dark') {
      document.body.setAttribute('data-theme', 'dark');
      this.darkMode();
    } else {
      document.body.setAttribute('data-theme', 'light');
      this.lightMode();
    }
  }

  // get tokens
  hid: any;
  getToken() {
    this.hid = this.apiservice.getToken();
    // console.log(this.hid);
  }
  // get users
  cid: any;
  getUser() {
    this.cid = this.apiservice.getUser();
    // console.log(this.cid);
  }
}
