import { Component, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { AlertController, IonRouterOutlet, ModalController, Platform } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { App } from '@capacitor/app';
import { FireserviceService } from './services/fireservice.service';
import { Device } from '@capacitor/device';
import { ApiserviceService } from './services/apiservice.service';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { ExappPage } from './exapp/exapp.page';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
  
import { AdMobFree, AdMobFreeBannerConfig } from '@ionic-native/admob-free/ngx';  
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  hideHeader: boolean = true;
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  webOs: any;
  webosver: any;
  webbrver: any;

  andmodel: any;
  anddevname: any;
  anddevos: any;

  pushes: any = [];
  themeSelect: any;
  public onlineOffline: boolean = navigator.onLine;

  constructor(
    private router: Router,
    private platform: Platform,
    private screenor: ScreenOrientation,
    private firedata: FireserviceService,
    private apiservice: ApiserviceService,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private statusBar: StatusBar,
    public admob: AdMobFree
  ) {
    // this.addSplashVideo();
  }

  ngOnInit() {
    // SplashScreen.show({
    //   showDuration: 4000,
    //   autoHide: true,
    // });
    // this.initializeAdMob();

    
    setTimeout(() => {
      SplashScreen.hide();
    }, 5000);

    this.logDeviceInfo();
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        // console.log(event.url);
        if (event.url === "/livematch") {
          this.hideHeader = false;
        } else {
          this.hideHeader = true;
        }
      }
    });

    if (this.platform.is('capacitor')) {
      this.initPush();
      this.lockScreen();
      this.backButton();
    }
    // console.log(Capacitor.getPlatform());
    this.firedata.login();

    if (!navigator.onLine) {
      //Do task when no internet connection
      // alert('No internet connection');
      this.nointernetalert();
    }
    window.addEventListener('offline', () => {
      //Do task when no internet connection
      // alert('No internet ');
      this.nointernetalert();
    });

    this.statusBar.overlaysWebView(false);

    // set status bar to white
    // this.statusBar.backgroundColorByHexString('#034281');
    this.themeSelect = localStorage.getItem('theme');
    if (this.themeSelect == 'light') {
      this.statusBar.backgroundColorByHexString('#034281');
    } else if (this.themeSelect == 'dark') {
      this.statusBar.backgroundColorByHexString('#0C131A');
    }
    if (!this.themeSelect) {
      this.statusBar.backgroundColorByHexString('#034281');
    }
  }
  showBanner() {  
    const bannerConfig: AdMobFreeBannerConfig = {  
        isTesting: true, // Remove in production  
        autoShow: true  ,
       //id: Your Ad Unit ID goes here  
        id: 'ca-app-pub-1658074074654406/2046701535'  
    };  
    this.admob.banner.config(bannerConfig);  
    this.admob.banner.prepare().then(() => {  
        // success  
    }).catch(e => console.log(e));  
}  
  // async initializeAdMob() {
  //   const options = {
  //     requestTrackingAuthorization: true, // Add this line if you are targeting iOS 14+
  //     initializeForTesting: false, // Set to true for testing
  //     appId: 'ca-app-pub-1658074074654406~1604710803',
  //       };

  //   await AdMob.initialize(options);
  // }
  // splash video
  // addSplashVideo() {
  //   const video = document.createElement('video');
  //   video.src = 'assets/video/splashvid.mp4';
  //   video.autoplay = true;
  //   video.muted = true;
  //   video.style.position = 'absolute';
  //   video.style.width = '100%';
  //   video.style.height = '100%';
  //   video.style.objectFit = 'cover';
  //   video.style.zIndex = '9999';

  //   video.addEventListener('loadedmetadata', () => {
  //     setTimeout(() => {
  //       SplashScreen.hide();
  //       video.style.display='none';
  //     }, 7000)
  //   });
  //   document.body.appendChild(video);
  // }


  // no internet
  async nointernetalert() {
    const alert = await this.alertController.create({
      header: 'Attention',
      subHeader: 'No internet connection',
      // message: 'This is an alert!',
      buttons: ['OK'],
      cssClass: 'nointcs'
    });

    await alert.present();
  }

  // get device information
  logDeviceInfo = async () => {
    const info = await Device.getInfo();
    if (Capacitor.getPlatform() == 'web') {
      this.webOs = info.operatingSystem;
      this.webosver = info.model;
      this.webbrver = info.webViewVersion;
      this.addWebUser();
    } else if (Capacitor.getPlatform() == 'android') {
      this.andmodel = info.model;
      this.anddevname = info.name;
      this.anddevos = info.osVersion;
      this.addAndUser();
    }
    // console.log(info);
  };

  // lock screen orientation
  lockScreen() {
    this.screenor.lock(this.screenor.ORIENTATIONS.PORTRAIT);
  }

  // add user function for android users

  addAndUser() {
    let date = new Date();
    let vdate = date.toISOString().slice(0, 19).replace('T', ' ');
    let data = {
      and_model: this.andmodel,
      and_devname: this.anddevname,
      and_version: this.anddevos,
      created_on: vdate,
      modify_on: vdate
    }
    this.apiservice.addAndUsers(data).subscribe((res: any) => {
      if (res.success == 1) {
        console.log('android user added');
      } else {
        console.log('Server error');
      }
    });
  }
  // add user function for web browsers
  addWebUser() {
    let date = new Date();
    let vdate = date.toISOString().slice(0, 19).replace('T', ' ');
    let data = {
      web_os: this.webOs,
      web_osversion: this.webosver,
      webbr_version: this.webbrver,
      created_on: vdate,
      modify_on: vdate
    }
    this.apiservice.addWebUsers(data).subscribe((res: any) => {
      if (res.success == 1) {
        console.log('web user added');
      } else {
        console.log('Server error');
      }
    });
  }

  // code for push notifications
  initPush() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      // alert('Push registration success, token: ' + token.value);
      let data = {
        key_node: token.value
      }
      this.apiservice.getuserTbyT(data).subscribe((res: any) => {
        if (res.success == 1) {
          if (res.data.length > 0) {
            // this.apiservice.errToast('User Id already taken by someone plz enter another user id');
            console.log('already token');
            return false;
          } else {
            // this.registerUser();
            this.saveFcmTid(token.value);
          }
        } else {
          this.apiservice.errToast('Something went wrong!');
          return false;
        }
      })
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.log(JSON.stringify(error));
      // alert('Error on registration: ' + JSON.stringify(error));
    });

    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        // alert('Push received: ' + JSON.stringify(notification));
        console.log('push received', JSON.stringify(notification));
      },
    );

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        console.log('push action perform', JSON.stringify(notification));
        // alert('Push action performed: ' + JSON.stringify(notification));
      },
    );
  }

  //
  // token saveed
  saveFcmTid(token) {
    let date = new Date();
    let vdate = date.toISOString().slice(0, 19).replace('T', ' ');
    // console.log('Push registration success, token: ' + token.value);
    // let vtoken = token;
    let data = {
      key_node: token,
      created_on: vdate,
      modify_on: vdate
    }
    this.apiservice.saveFcmT(data).subscribe((res: any) => {
      if (res.success == 1) {
        console.log('save successfully');
      } else {
        console.log("server error");
      }
    });
  }
  // go back button to exit app
  backButton() {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (!this.routerOutlet.canGoBack()) {
        // App.exitApp();
        // this.showExitConfirm();
        this.showExitModal();
      }
    });
  }


  // open modal to exit app
  async showExitModal() {
    const modal = await this.modalCtrl.create({
      component: ExappPage,
      cssClass: 'themepop',
      breakpoints: [0, 0.32],
      initialBreakpoint: 0.32
    });
    return await modal.present();
  }
}

