import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { Livedata } from '../models/livemdata';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable } from 'rxjs';
import firebase from 'firebase/compat/app';
import { LoadingController } from '@ionic/angular';
export class Item {
  body: string;
}

@Injectable({
  providedIn: 'root'
})
export class FireserviceService {
  confirmationResult: firebase.auth.ConfirmationResult;

  private dbPath = '/livedata/';
  livemdataRef: AngularFireList<Livedata>;
  livemobj: AngularFireObject<Livedata>;
  newlive: Observable<any>;

  // new code
  userId: any;
  items: AngularFireList<Item[]> = null; // firebase object

  constructor(
    private db: AngularFireDatabase,
    private fireauth: AngularFireAuth,
    private loading: LoadingController
  ) {
  }

  // anonymous user login into firebase
  login() {
    this.signInAnonymously().then(
      (userData) => {
        if (this.userId != undefined || '') {
          this.userId = userData.user._delegate.uid;
        } else {
          this.userId = Math.floor(Math.random() * Date.now()).toString(16);
        }
        // console.log('userid under login', userData.user._delegate.uid);
      }
    ).catch(err => {
      if (err) {
        // this.presentToast(`${err}`, 'bottom', 2100);
        console.log('errrrr', err);
      }

    });
    // .then(el => this.closeLoading());
  }
  // sign in anonymous
  private signInAnonymously() {
    return new Promise<any>((resolve, reject) => {
      this.fireauth.signInAnonymously().then((data) => {
        resolve(data);
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        reject(`login failed ${error.message}`);
        // ...
      });
    });
  }

  // function to get userId
  getId() {
    return this.userId;
  }

  // get loggged in user data
  nuserid: any;
  getLogUid() {
    this.fireauth.authState.subscribe(user => {
      if (user) {
        // this.userId = user.uid;
        this.nuserid = user.uid;
        // console.log('getloguid', user.uid);
      }
    });
  }

  // logout from firebase
  logout() {
    this.fireauth.signOut().then(() => {
      // this.router.navigate(['/login']);
      console.log('logout successfully');
    });
  }

  // write userdata by its login uid
  writeUserData(tutorial: Livedata): any {
    this.fireauth.authState.subscribe(user => {
      if (user) {
        // this.userId = user.uid;
        this.nuserid = user.uid;
        // console.log('getloguid',user.uid);
        // if (this.nuserid) {
        this.livemobj = this.db.object(this.dbPath + this.nuserid);
        // } else {
        this.livemdataRef = this.db.list(this.dbPath + this.nuserid);
        // console.log('userid in writeuserdata', this.nuserid);
        return this.livemdataRef.push(tutorial);
        // }

      }
    });
    // this.getLogUid();
    // console.log(this.nuserid);
    // this.db.list('users/' + data.uid).set(data).catch(error => {
    // working code this.db.list('users/' + this.userId).push(data).catch(error => {
    //   console.log(error.message)
    // });
    // return this.db.list(uid).push(data).catch(error => {
    //   console.log(error.message);
    // });
    // this.livemdataRef = this.db.list(this.dbPath + this.userId);
    // console.log('userid in writeuserdata',this.nuserid);
    // this.livemdataRef = this.db.list(this.dbPath + this.nuserid);
    // return this.livemdataRef.push(tutorial);
  }

  // get userdata by its login id
  getUserData(): AngularFireList<Livedata> {
    // this.db.list('users/' + this.userId).("value", snap => {
    //   console.log(snap.val())
    // })
    // return this.db.list(newlive<uid>);
    return this.livemdataRef;
  }

  getAll(): AngularFireList<Livedata> {
    // if (!this.userId) return;
    return this.livemdataRef;
  }

  getbyObj(): AngularFireList<Livedata> {
    return this.livemdataRef;
  }

  getById(key: string) {
    this.livemobj = this.db.object(this.dbPath + key);
    return this.livemobj;
  }

  create(tutorial: Livedata): any {
    return this.livemdataRef.push(tutorial);
  }

  update(key: string, value: any): Promise<void> {
    return this.livemdataRef.update(key, value);
  }

  delete(key: string): Promise<void> {
    return this.livemdataRef.remove(key);
  }

  deleteAll(): Promise<void> {
    return this.livemdataRef.remove();
  }

  /* Delete one record */
  delOne(key: string): Promise<void> {
    this.fireauth.authState.subscribe(user => {
      if (user) {
        this.nuserid = user.uid;
        this.nuserid.delete();
      }
    });
    this.livemobj = this.db.object(this.dbPath + key);
    return this.livemobj.remove().catch((error) => {
      this.errorMgmt(error);
    });
  }

  deleteOne(key: string) {
    return this.livemdataRef.remove(key);
  }

  // deleteAll(): Promise<void> {
  //   return this.livemdataRef.remove();
  // }
  // Error management
  private errorMgmt(error) {
    console.log(error);
  }

  //
  public async signInWithPhoneNumber(recaptchaVerifier, phoneNumber) {
    let loading = await this.loading.create({
      spinner: 'circular',
      translucent: true,
      cssClass: 'loaderscss',
      backdropDismiss: true,
      keyboardClose: true
    });
    await loading.present();
    return new Promise<any>((resolve, reject) => {

      this.fireauth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
          resolve(confirmationResult);
          this.loading.dismiss();
        }).catch((error) => {
          console.log(error);
          reject('SMS not sent');
        });
    });
  }
  public async enterVerificationCode(code) {
    let loading = await this.loading.create({
      spinner: 'circular',
      translucent: true,
      cssClass: 'loaderscss',
      backdropDismiss: true,
      keyboardClose: true
    });
    await loading.present();
    return new Promise<any>((resolve, reject) => {
      this.confirmationResult.confirm(code).then(async (result) => {
        console.log(result);
        const user = result.user;
        resolve(user);
        this.loading.dismiss();
      }).catch((error) => {
        reject(error.message);
      });

    });
  }

}
