import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'newsdetails/:news_id',
    loadChildren: () => import('./newsdetails/newsdetails.module').then( m => m.NewsdetailsPageModule)
  },
  {
    path: 'newsdetailsTreanding',
    loadChildren: () => import('./trending-news/trending-news.module').then( m => m.TrendingNewsPageModule)
  },
  {
    path: 'fixtures',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule)
  },
  {
    path: 'more',
    loadChildren: () => import('./tab5/tab5.module').then( m => m.Tab5PageModule)
  },
  {
    path: 'livematch/:match_id/:id',
    loadChildren: () => import('./livematch/livematch.module').then( m => m.LivematchPageModule)
  },
  {
    path: 'pleleveninfo',
    loadChildren: () => import('./pleleveninfo/pleleveninfo.module').then( m => m.PleleveninfoPageModule)
  },
  {
    path: 'fantoppicks',
    loadChildren: () => import('./fantoppicks/fantoppicks.module').then( m => m.FantoppicksPageModule)
  },
  {
    path: 'teamranking',
    loadChildren: () => import('./teamranking/teamranking.module').then( m => m.TeamrankingPageModule)
  },
  {
    path: 'trdetails/:type',
    loadChildren: () => import('./trdetails/trdetails.module').then( m => m.TrdetailsPageModule)
  },
  {
    path: 'pranking',
    loadChildren: () => import('./pranking/pranking.module').then( m => m.PrankingPageModule)
  },
  {
    path: 'prankdetails/:type',
    loadChildren: () => import('./prankdetails/prankdetails.module').then( m => m.PrankdetailsPageModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'termandc',
    loadChildren: () => import('./termandc/termandc.module').then( m => m.TermandcPageModule)
  },
  {
    path: 'privacypolicy',
    loadChildren: () => import('./privacypolicy/privacypolicy.module').then( m => m.PrivacypolicyPageModule)
  },
  {
    path: 'languagepop',
    loadChildren: () => import('./languagepop/languagepop.module').then( m => m.LanguagepopPageModule)
  },
  {
    path: 'themepop',
    loadChildren: () => import('./themepop/themepop.module').then( m => m.ThemepopPageModule)
  },
  {
    path: 'blogdetail/:blog_id',
    loadChildren: () => import('./blogdetail/blogdetail.module').then( m => m.BlogdetailPageModule)
  },
  {
    path: 'headermenu',
    loadChildren: () => import('./headermenu/headermenu.module').then( m => m.HeadermenuPageModule)
  },
  {
    path: 'trendingseries',
    loadChildren: () => import('./trendingseries/trendingseries.module').then( m => m.TrendingseriesPageModule)
  },
  {
    path: 'trserieslist/:series_id',
    loadChildren: () => import('./trserieslist/trserieslist.module').then( m => m.TrserieslistPageModule)
  },
  {
    path: 'livemlist',
    loadChildren: () => import('./livemlist/livemlist.module').then( m => m.LivemlistPageModule)
  },
  {
    path: 'searchpl',
    loadChildren: () => import('./searchpl/searchpl.module').then( m => m.SearchplPageModule)
  },
  {
    path: 'pllist',
    loadChildren: () => import('./pllist/pllist.module').then( m => m.PllistPageModule)
  },
  {
    path: 'plinfo/:id',
    loadChildren: () => import('./plinfo/plinfo.module').then( m => m.PlinfoPageModule)
  },
  {
    path: 'signin',
    loadChildren: () => import('./signin/signin.module').then( m => m.SigninPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'chpass',
    loadChildren: () => import('./chpass/chpass.module').then( m => m.ChpassPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'forgotpass',
    loadChildren: () => import('./forgotpass/forgotpass.module').then( m => m.ForgotpassPageModule)
  },
  {
    path: 'mominfo',
    loadChildren: () => import('./mominfo/mominfo.module').then( m => m.MominfoPageModule)
  },
  {
    path: 'vslangpop',
    loadChildren: () => import('./vslangpop/vslangpop.module').then( m => m.VslangpopPageModule)
  },
  {
    path: 'lazyload',
    loadChildren: () => import('./lazyload/lazyload.module').then( m => m.LazyloadPageModule)
  },
  {
    path: 'shorts',
    loadChildren: () => import('./shorts/shorts.module').then( m => m.ShortsPageModule)
  },
  {
    path: 'comingsoon',
    loadChildren: () => import('./comingsoon/comingsoon.module').then( m => m.ComingsoonPageModule)
  },
  {
    path: 'lmlistlive',
    loadChildren: () => import('./lmlistlive/lmlistlive.module').then( m => m.LmlistlivePageModule)
  },
  {
    path: 'bloglist',
    loadChildren: () => import('./bloglist/bloglist.module').then( m => m.BloglistPageModule)
  },
  {
    path: 'exapp',
    loadChildren: () => import('./exapp/exapp.module').then( m => m.ExappPageModule)
  },
  {
    path: 'womenrank',
    loadChildren: () => import('./womenrank/womenrank.module').then( m => m.WomenrankPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
